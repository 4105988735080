<template>
  <div>
    <el-card class="box-card" shadow="always">
      <el-row type="flex" justify="space-between">
        <el-col :span="3">关于先普</el-col>
        <el-col :span="7">
          <img-upload :url="list[0].data.bgImg" @change="setUrl($event, 0)"></img-upload>
        </el-col>
        <el-col :span="13">
          <el-input type="textarea"  v-model="list[0].data.content" :autosize="{ minRows: 8, maxRows: 8}"></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="3">产品中心</el-col>
        <el-col :span="7">
          <img-upload :url="list[1].data.bgImg" @change="setUrl($event, 1)"></img-upload>
        </el-col>
        <el-col :span="13">
          <el-input type="textarea"  v-model="list[1].data.content" :autosize="{ minRows: 8, maxRows: 8}"></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="3">产品选型</el-col>
        <el-col :span="7">
          <img-upload :url="list[2].data.bgImg" @change="setUrl($event, 2)"></img-upload>
        </el-col>
        <el-col :span="13">
          <el-input type="textarea"  v-model="list[2].data.content" :autosize="{ minRows: 8, maxRows: 8}"></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="3">应用方案</el-col>
        <el-col :span="7">
          <img-upload :url="list[3].data.bgImg" @change="setUrl($event, 3)"></img-upload>
        </el-col>
        <el-col :span="13">
          <el-input type="textarea"  v-model="list[3].data.content" :autosize="{ minRows: 8, maxRows: 8}"></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="3">新闻中心</el-col>
        <el-col :span="7">
          <img-upload :url="list[4].data.bgImg" @change="setUrl($event, 4)"></img-upload>
        </el-col>
        <el-col :span="13">
          <el-input type="textarea"  v-model="list[4].data.content" :autosize="{ minRows: 8, maxRows: 8}"></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="3">联系我们</el-col>
        <el-col :span="7">
          <img-upload :url="list[5].data.bgImg" @change="setUrl($event, 5)"></img-upload>
        </el-col>
        <el-col :span="13">
          <el-input type="textarea"  v-model="list[5].data.content" :autosize="{ minRows: 8, maxRows: 8}"></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-button size="medium" type="primary" style="min-width: 120px" @click="submit">保存</el-button>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {getFirstScreen, updateFirstScreen} from "@/plugins/api/other";
import ImgUpload from "@/components/ImgUpload";

export default {
  name: 'Banner',
  components: {ImgUpload},
  data() {
    return {
      aboutUrl: '',
      aboutDesc: '',
      list: [
        {
          module: 'about',
          data: {},
        },
        {
          module: 'product_center',
          data: {},
        },
        {
          module: 'product_spec',
          data: {},
        },
        {
          module: 'application',
          data: {},
        },
        {
          module: 'press_center',
          data: {},
        },
        {
          module: 'contact_us',
          data: {},
        },
      ]
    }
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    async getScreen() {
      this.list.forEach(async (x, index) => {
        let res = await getFirstScreen({'which_module': x.module});
        this.list[index].data = res[0]
      })
    },
    async submit() {
      this.list.forEach(async (x, index) => {
        let res = await updateFirstScreen({
          'which_module': x.module,
          'id': x.data.id,
          'title': x.data.title,
          'content': x.data.content,
          'bg_img': x.data.bgImg,
        });
      })
      this.getScreen()
    },
    setUrl(url, index) {
      this.list[index].data.bgImg = url;
    },
  },
  created() {
    this.getScreen()
  }
}
</script>
<style scoped>
.el-row {margin-bottom: 20px}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 90%;
  height: auto;
  max-height: 200px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 90%;
  height: auto;
  max-height: 200px;
  display: block;
}
</style>
